import './text-image-teaser.scss';

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

class TextImageTeaser {
    constructor (element) {
        this.$textImageTeaser = element;
        this.orientation = this.$textImageTeaser.getAttribute('data-text-image-teaser-orientation');
        this.slider = null;
        this.$textImageTeaserItems = this.$textImageTeaser.querySelectorAll('[data-text-image-teaser="slide"]');
        this.$sliderContainer = this.$textImageTeaser.querySelector('[data-text-image-teaser="slider"]');
        this.sliderActive = false;
        this.count = parseInt(this.$textImageTeaser.dataset.textImageTeaserCount);
    }

    initialize () {
        if (this.count > 1) {
            this.checkSizes();
            // this.setEvents();
        }
    }

    initSlider () {
        this.sliderActive = true;

        this.slider = new Swiper(this.$sliderContainer, {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            speed: 500,
            autoHeight: false,
            pagination: {
                el: this.$textImageTeaser.querySelector('.swiper-pagination'),
                type: 'progressbar'
            },
            navigation: {
                nextEl: this.$textImageTeaser.querySelector('.slider__navigation-pagination-next'),
                prevEl: this.$textImageTeaser.querySelector('.slider__navigation-pagination-prev')
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 0
                },
                1440: {
                    slidesPerView: 3
                }
            }
        });
    }

    destroySlider () {
        if (this.slider !== null) {
            this.slider.destroy(true, true);
            this.slider = null;
            this.sliderActive = false;
        }
    }

    checkSizes () {
        if (!window.mediaMatch[768].matches) {
            if (this.count >= 2) {
                this.initSlider();
            }
        } else {
            if (this.count > 2 && !window.mediaMatch[1024].matches) {
                this.initSlider();
            }

            if (this.count === 3 && !window.mediaMatch[1440].matches) {
                this.initSlider();
            }

            if (this.count > 3 && window.mediaMatch[1440].matches) {
                this.showNav = true;
                this.initSlider();
            }
        }

        window.mediaMatch[768].addEventListener('change', (match) => {
            if (!match.matches && !this.sliderActive) {
                if (this.count >= 2) {
                    this.initSlider();
                }
            } else if (match.matches && this.sliderActive) {
                if (this.count === 2) {
                    this.destroySlider();
                }
            }
        });

        window.mediaMatch[1024].addEventListener('change', (match) => {
            if (!match.matches && !this.sliderActive) {
                if (this.count > 2) {
                    this.initSlider();
                }
            } else if (match.matches && this.sliderActive) {
                if (this.count === 2) {
                    this.destroySlider();
                }
            }
        });

        window.mediaMatch[1440].addEventListener('change', (match) => {
            if (!match.matches && !this.sliderActive) {
                if (this.count === 3) {
                    this.destroySlider();
                }
            } else if (match.matches && this.sliderActive) {
                if (this.count > 3) {
                    this.initSlider();
                }
            }
        });
    }
}

export { TextImageTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $textImageTeasers = $context.querySelectorAll('[data-text-image-teaser="root"]');

        for (let i = 0; i < $textImageTeasers.length; i++) {
            const $textImageTeaser = new TextImageTeaser($textImageTeasers[i]);
            $textImageTeaser.initialize();
        }
    }
});
